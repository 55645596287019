/* Customize your main colors in :root variables */
:root {
  --main-background-color: #0a7500;
  --card-background-color: #579351;
  --card-background-lighter-color: #01fc11;
  --main-text-color:#F7F6F4;
  --title-text-color:#f3f3f3;
}

body{
  background-color:var(--main-background-color);
  /*background: url("img/background.png");
  background-size: cover;*/

  font-family: 'Patrick Hand', cursive;
  font-size: 1.1em;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}



